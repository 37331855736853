<template>
  <div class="loading mb-3 text-center" >
    <font-awesome-icon :icon="['fas', 'sync-alt']" spin v-if="loading"/>
  </div>
  <div v-if="!obj && !loading" class="not-found">
    <font-awesome-icon :icon="['fas', 'unlink']" size="6x" class="mb-3"/>
    <h2>Veículo indisponível para oferta ;(</h2>
  </div>
  <div id="details" v-if="obj && !loading">
    <div class="row">
      <div class="col-12">
        <div class="row align-items-center">
          <div class="col-lg-8">
            <h1 class="title">{{obj.marca}}</h1>
            <h1 class="title pb-4">{{obj.modelo}}</h1>
          </div>
          <div class="col-lg-4">
            <div class="row actions">
              <div class="col-4" v-if="obj.iosDownloadLink">
                <a :href="obj.iosDownloadLink" class="btn" style="padding: 0">
                  <img src="/app-store.png" style="width: 100%;"/>  
                </a>
              </div>
              <div class="col-4" v-if="obj.androidDownloadLink">
                <a :href="obj.androidDownloadLink" class="btn" style="padding: 0">
                  <img src="/play-store.png" style="width: 100%;"/>  
                </a>
                  
              </div>
              <div class="col-4">
                <a :href="obj.whatsAppMobileGerenteUrl" class="btn btn-block btn-sm btn-light ">
                  <font-awesome-icon :icon="['fab', 'whatsapp']" size="lg"/>FALE CONOSCO</a>
                  
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-lg-8">
        <div id="gallery" class="carousel slide">
          <div class="carousel-indicators">
            <button 
              type="button" 
              data-bs-target="#gallery" 
              :key="i.codigoImagem" 
              v-for="(i,index) in obj.imagens" 
              :data-bs-slide-to="index" 
              :aria-label="'Foto '+index"
              :class="ativeSlide === index && 'active'" 
              :aria-current="ativeSlide === index && 'true'"></button>
          </div>
          <div class="carousel-inner">
            <div :class="'carousel-item '+ (index === ativeSlide && 'active')" :key="i.codigoImagem" v-for="(i,index) in obj.imagens">
              <img :src="`${i.imagemUrl}`" class="w-100" alt="...">
            </div>
            
          </div>
          <button class="carousel-control-prev" type="button" data-bs-target="#gallery" data-bs-slide="prev">
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Previous</span>
          </button>
          <button class="carousel-control-next" type="button" data-bs-target="#gallery" data-bs-slide="next">
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Next</span>
          </button>
        </div>
        <div class="opcionais" v-if="showOpcionais">
          <h5>Opcionais</h5>
          <div class="row">
            <div class="col-lg-6" v-for="(i,index) in obj.opcionais" :key="index">
              <ul>
                <li v-for="j in i" :key="j.codigoOpcional">
                  <span>
                    <font-awesome-icon :icon="['fas', 'check']" />
                    {{j.descricaoOpcional}}
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="checklist" v-if="showChecklist">
          <h5>Checklist</h5>
          <div class="row">
            <div class="col-lg-6" v-for="(i,index) in obj.checklists" :key="index">
              <ul>
                <li v-for="j in i" :key="j.codigoChecklist">
                  <span>
                    <font-awesome-icon :icon="['fa'+(j.conservacao < 3 ? 's' : 'r'), 'star']" />
                    <font-awesome-icon :icon="['fa'+(j.conservacao < 2 ? 's' : 'r'), 'star']" />
                    <font-awesome-icon :icon="['fa'+(j.conservacao < 1 ? 's' : 'r'), 'star']" />
                    {{j.checklist}}
                  </span>
                  <small>
                    {{j.descricaoServico}}
                  </small>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="obs">
          <h5>Mais informações</h5>
          <div class="row">
            <div class="col-12" v-html="obj.observacaoAvaliacao"></div>
          </div>
        </div>
      </div>


      <div class="col-12 col-lg-4">
        <div class="row">
          <div class="col-12 order-2 order-lg-1">
            <div class="row ">
              <div class="col-12">
                <div :class="'oferta '+(obj.valorArremate > 0 && 'bg-danger')">
                  <span class="valor">{{valorFormatado}}</span>
                  <span class="tip">{{labelValor}}</span>
                  <button class="btn btn-primary btn-lg" type="button" @click="openModal">ENVIAR OFERTA</button>
                </div>
              </div>
              <!-- <div class="col-12 proposta">
                <span>
                  Pensou em outro valor? <br/><a href="#" data-bs-toggle="modal" data-bs-target="#propostaModal">Clique aqui</a> e envie sua proposta
                </span>
              </div> -->
            </div>
          </div>
          <div class="col-12 order-1 order-lg-2">
            <div class="row detalhes">
              <div class="col-12">
                <h5>Detalhes</h5>
                <table>
                  <tbody>
                    <tr>
                      <td>
                        Localização:
                      </td>
                      <td>
                        {{obj.cidade}}/{{obj.siglaEstado}}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        Ano:
                      </td>
                      <td>
                        {{obj.anoFabricacao}}/{{obj.anoModelo}}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        KM:
                      </td>
                      <td>
                        {{obj.kmFormatado}}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        Cor:
                      </td>
                      <td>
                        {{obj.cor}}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        Portas:
                      </td>
                      <td>
                        {{obj.numeroPortas}}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        Combustível:
                      </td>
                      <td>
                          {{obj.combustivel}}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        Transmissão:
                      </td>
                      <td>
                          {{obj.transmissao}}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        Placa:
                      </td>
                      <td>
                        {{obj.placaFormatado}}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        Carroceria:
                      </td>
                      <td>
                          {{obj.carroceria}}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        Valor FIPE:
                      </td>
                      <td>
                        {{obj.valorFipeFormatado}}
                      </td>
                    </tr>
                    <tr v-if="obj.leilao">
                      <td colspan="2" class="text-danger text-bold text-center">
                        Veículo consta leilão
                      </td>
                    </tr>
                    
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div class="col-12 order-3 order-lg-3">
            <div class="gerente">
              <h5>Contato</h5>
              <div class="row">
                <div class="col-6 col-lg-12">
                  <div class="contato">
                    <div class="photo">
                      <img :src="obj.imagemPerfilGerenteFormatadoUrl" >
                    </div>
                    <div class="name" >
                      <span>
                        {{obj.nomeGerente}}
                      </span>
                      <a href="#">
                        {{obj.telefoneGerenteFormatado}}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal -->
    <div class="modal fade" id="ofertaModal" tabindex="-1" aria-labelledby="ofertaModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="ofertaModalLabel">Envie sua oferta de {{valorFormatado}} ou mais</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div class="input-group mb-3 input-group-lg">
              <button class="btn  btn-outline-secondary" type="button" id="button-addon1" @click="minus"><font-awesome-icon :icon="['far', 'minus-square']" size="lg" /></button>
              <input type="text"  v-model="valorOfertaFormatado" readonly="readonly" class="form-control " placeholder="" aria-label="Example text with button addon" aria-describedby="button-addon1">
              <button class="btn btn-outline-secondary" type="button" id="button-addon2" @click="plus"><font-awesome-icon :icon="['far', 'plus-square']" size="lg" /></button>
            </div>
            Esta oferta não garante a compra do veículo, a mesma dependerá do aceite do valor pelo cliente.
            <br/><br/>Caso o cliente aceite este valor, você se compromete a pagar R$ {{valorOfertaFormatado}} + {{obj.valorTaxaAdmnistrativaFormatado}} (ref. taxa administrativa) pela compra do veículo.
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary-outline" data-bs-dismiss="modal">FECHAR</button>
            <button type="button" :disabled="sending" class="btn btn-primary send" @click="enviar">
              <span v-if="!sending">ENVIAR OFERTA</span>
              <font-awesome-icon :icon="['fas', 'sync-alt']" spin 
              v-if="sending"/>
            </button>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<style scoped>
html,body,.content{
  background: var(--white)!important
  }
  
  .send{
    min-width: 150px;
  }
  h1{
    font-size:1.8rem;
    font-weight: 600;
  }
  #details{
    margin-bottom: 1.5rem;
  }
  #gallery{
    background: var(--darker)
  }

  #gallery .carousel-item{
    height: 460px;
  }

  #gallery img {
    /* height: 100%; */
    position: absolute;
    top: -9999px;
    bottom: -9999px;
    left: -9999px;
    right: -9999px;
    margin: auto;
  }

  .oferta {
    background-color: var(--dark);
    color: var(--white);
    text-align:center;
    padding: 14px 10px 20px;
  }
  .oferta .valor{
    font-weight: 600;
    font-size: 36px;
    display: block;
  }
  
  .oferta .tip{
    font-size: 12px;
    text-transform: uppercase;
    margin: 3px 0 0 0;
    padding: 0;
    display: block;

  }

  .oferta button{
    margin-top: 18px;
    height: 49px;
    font-size: 14px;
    padding-left: 40px;
    padding-right: 40px;
    background: var(--primary-color);
  }
  .proposta {
    text-align: center;
    margin-top: 0.5rem;
    
  }
  .text-center{
    text-align: center
  }

  .proposta span{
    color: var(--dark);
    font-size: 12px;
    padding: 12px 0 2px 0;
    text-transform: uppercase;
    font-weight: 500;
  }

  .detalhes,.opcionais,.checklist, .obs,.gerente{
        margin-top: 40px;
  }
  .gerente{
    margin-bottom: 1.5rem;
  }
  h5{
    font-size: 18px;
    line-height: 26px;
    font-weight: 600;
    margin: 0;
    padding: 0 0 21px 0;
  }

  .detalhes table{
    width: 100%;
    border-spacing: 1px;
    border-collapse: separate;
  }
  
  .detalhes table td,
  .detalhes table th{
    font-size: 12px;
    line-height: 1.3;
    text-transform: uppercase;
    padding: 9px 12px;
  }

  .detalhes table tr td:last-child{
    background-color: #fcfcfc;
    font-weight: 600;
  }
  .detalhes table tr td:first-child{
        background-color: #f7f7f7;
  }

  .opcionais ul,
  .checklist ul{
    list-style: none;
    padding: 0 0 0 0px;
    margin: 0;
  }
  .opcionais li,
  .checklist li{
    padding: 0 0 4px 27px;
    margin-bottom: 10px;
    font-weight: 500;
    text-transform: uppercase;
    position: relative;
    font-size: 12px;
    line-height: 1.4;
    color: var(--darker)
  }
  .opcionais li svg{
    position: absolute;
    top: 2px;
    left: 8px;
    line-height: 1;
    color: var(--primary-color);
    vertical-align: baseline;
  }
  .checklist li svg{
    color: var(--primary-color);
    vertical-align: baseline;
  } 
  .checklist li{
    padding-left: 0;
  }
  .checklist small{
    color: var(--dark);
    display: block;
    min-height: 10px;
    margin-top: 3px;
  }

  .obs{
    font-size: 12px
  }

  .contato{
    display: flex;
    flex-direction: row;
    height: 100%;
    padding: 0;
    align-items:center;
    margin-bottom: 1rem
  }
  .contato .photo{
    width: 35px;
    height: 35px;
    border-radius: 50%
  }
  .contato .photo img{
    width: 100%;
    border-radius: 30px;
  }
  .contato .name{
    text-align: left;
    margin-left: 8px;
  }
  .contato{
    font-weight: 500;
    text-transform: uppercase;
    position: relative;
    font-size: 12px;
    line-height: 1.4;
    color: var(--darker);
  }
  .contato span{
    display:block
  }
  .modal-title{
    padding:0;
  }
  #ofertaModal input,
  #propostaModal input {
    text-align: center;
    font-weight: 600;
    color: var(--primary-color)
  }
  .bg-danger{
    background: var(--secondary-color)!important;
    /* color: var(--primary-lighter)!important */
  }
  .bg-danger button{
    background: var(--primary-lighter)!important;
    color: var(--secondary-color)!important;
    /* color: var(--primary-lighter)!important */
  }
  .not-found{
    text-align: center;
  }
  @media (max-width: 992px){
    .actions > div:first-child{
      text-align: left;
    }
    .actions > div:last-child{
      text-align: right;
    }
    .actions > div{
      margin-bottom: 1.5rem;
    }
    .oferta{
      margin-top: 1.5rem;
    }
    .actions .btn {
      font-size: 0.75rem!important;
    }
    
  }
  @media (max-width: 576px){
    #gallery .carousel-item{
      height: 280px;
    }
    .actions .btn {
      font-size: 0.55rem!important;
      padding: 4px 12px 4px
    }
  }
</style>

<script>
import { library } from '@fortawesome/fontawesome-svg-core'
import { faWhatsapp, faFacebook } from '@fortawesome/free-brands-svg-icons'
import { faShare, faLink,faCheck,faStar,faUnlink } from '@fortawesome/free-solid-svg-icons'
import { faStar as faStarRegular,faMinusSquare,faPlusSquare} from '@fortawesome/free-regular-svg-icons'
import { isMobile, formatNumber } from '@/services/util.js';
import api from '@/services/api';
import { Modal } from 'bootstrap/dist/js/bootstrap.esm.min.js';
import {companyPath} from '@/services/auth';
import {getItem} from '@/services/localdb';

library.add(faUnlink,faWhatsapp, faFacebook, faShare,faLink,faCheck,faStar,faStarRegular,faMinusSquare,faPlusSquare);

export default {
  data() {
    return {
      obj: null,
      loading: true,
      ativeSlide: 0,
      valorOferta: 0,
      valorOfertaFormatado: 0,
      sending: false,
      backofficePath: ''
    }
  },
  methods: {
    plus() {
      let v = this.valorOferta + this.incremento;
      if(v < this.valorMaximo) {
        this.valorOferta = v;
        this.valorOfertaFormatado = formatNumber(this.valorOferta);
      }
    },
    minus() {
      let v = this.valorOferta - this.incremento;
      if(v > this.valorMinimo) {
        this.valorOferta = v;
        this.valorOfertaFormatado = formatNumber(this.valorOferta);
      }
    },
    copy(link) {
      
      let el = document.createElement('textarea');
      el.value = link;
      el.setAttribute('readonly', '');
      el.style.position = 'absolute';
      el.style.textTransform = 'lowercase';
      el.style.left = '-9999px';
      

      if (isMobile()) {
          var tempForm = document.createElement('form');
          tempForm.style.position = 'absolute';
          tempForm.style.left = '-9999px';
          tempForm.appendChild(el);

          document.body.appendChild(tempForm);

          iosCopyToClipboard(el);

          document.body.removeChild(tempForm);

      }
      else {
          document.body.appendChild(el);
          el.select();
          document.execCommand('copy');
          document.body.removeChild(el);
      }
    },
    openModal(){
      let element = document.querySelector('.modal');
      let modal = new Modal(element)
      modal.show();
    },
    async enviar(){
      this.sending = true;
      try {
        var response = await api.post(`/v1/negociacoes/${this.obj.codigoNegociacao}/oferta`, {valor: this.valorOferta})
          if(response.status  == 201) {
        // this.$notify({
        //   title: 'Oferta enviada com sucesso!',
        //   text: 'Você poderá acompanhar a resposta na tela "Minhas Ofertas"',
        //   type: 'success'
        // });
          alert('Oferta enviada com sucesso! \n\nVocê poderá acompanhar a resposta na tela "Minhas Ofertas"');
          
          let element = document.querySelector('.modal');
          let modal = Modal.getInstance(element);

          if(modal)
            modal.hide();

          this.$router.push('/minhas-ofertas');
        }
      }
      catch{}
      finally{
        this.sending = false;
      }
    }
  },
  computed: {
    valorFormatado() {
      if(this.obj.valorArremate > 0) {
        return this.obj.valorArremateFormatado;
      }
      if(this.obj.valorMaiorOferta > 0) {
        return this.obj.valorMaiorOfertaFormatado;
      }
      return this.obj.valorPretendidoFormatado;
    },
    valor() {
      if(this.obj.valorArremate > 0) {
        return this.obj.valorArremate;
      }
      if(this.obj.valorMaiorOferta > 0) {
        return this.obj.valorMaiorOferta;
      }
      return this.obj.valorPretendido;
    },
    labelValor(){
      if(this.obj.valorArremate > 0) {
        return 'FECHA AGORA!';
      }
      if(this.obj.valorMaiorOferta > 0) {
        return 'MELHOR OFERTA'
      }
      return 'VALOR INICIAL';
    },
    maiorQue10mil() {
      return this.valor <= 10000;
    },
    valorMinimo(){
      return Math.ceil((this.valor - (this.valor * 0.3))/1000)*1000;
    },
    valorMaximo(){
      return Math.round((this.obj.valorFipe * 1.1)/1000) * 1000;
    },
    incremento(){
      return this.maiorQue10mil ? 250 : 500
    }
  },
  beforeMount (){
    
  },
  async mounted() {
    let id = this.$route.params['id'];
    if(!id)
      this.$router.push('/');
    
    try{
      this.backofficePath = `${location.protocol}//${companyPath}.repasys.com.br`
      let response = await api.get(`/v1/negociacoes/${id}/detalhes`);
      this.obj = response.data;

      let company = JSON.parse(getItem('company') || false);


      if(company && company.iosDownloadLink) {  
        this.obj.iosDownloadLink = company.iosDownloadLink;
        this.obj.androidDownloadLink = company.androidDownloadLink;
      }

      this.showChecklist = this.obj.checklists.length > 0;
      this.showOpcionais = this.obj.opcionais.length > 0;

      let arr = this.obj.opcionais;

      let half = Math.ceil(arr.length / 2);

      this.obj.opcionais = [ arr.filter((i,index) => (index + 1) <= half),
      arr.filter((i,index) => (index + 1) > half) ];

      arr = this.obj.checklists;
      half = Math.ceil(arr.length / 2);

      this.obj.checklists = [ arr.filter((i,index) => (index + 1) <= half),
      arr.filter((i,index) => (index + 1) > half) ];

      this.valorOferta = this.valor;
      this.valorOfertaFormatado = formatNumber(this.valor);

      let defaultImg = `/Content/img/user.png`;

      this.obj.imagemPerfilNegociadorUrlFormatadoUrl = this.obj.imagemPerfilNegociadorUrlFormatadoUrl || defaultImg;
      this.obj.imagemPerfilGerenteFormatadoUrl = this.obj.imagemPerfilGerenteFormatadoUrl|| defaultImg;

    } 
    catch{}
    finally{
      this.loading = false;
    }
  }
}
</script>
